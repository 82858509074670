'use strict'

/**
 * Full height introduction screen.<br />
 * Global styling and background images can be changed in the `Home.scss` file.<br />
 */
export default {
    name: 'Home',
    props: {
        /**
         * Changes the template of the Home.<br />
         * Available values : `full-page` | `product`
         */
        homeStyle: {
            type: String,
            required: false,
            validator: (value) => {
                return ['full-page', 'product'].indexOf(value.toLowerCase()) !== -1
            }
        }
    }
}
