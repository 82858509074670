'use strict'

const parallax = {
    inserted: (element, binding) => {
        if (!binding.value) binding.value = 10 // default speed value
        let initialTopPosition = 0

        // Need to have a function without parameters to be used as a listener so we can remove the eventListener when not used
        function updatePosition() {
            let topPosition = element.getBoundingClientRect().y
            let speed = binding.value // the higher, the slower

            let parallaxTop = initialTopPosition
            if (parallaxTop === 0) initialTopPosition = topPosition / speed // avoid the little jump at the init
            else element.style.transform = `translateY(${(topPosition / speed) - parallaxTop}px)`
        }

        // Update position when the element is on screen
        let observer = new IntersectionObserver((o) => {
            if (o[0].isIntersecting) { // on screen
                window.addEventListener('scroll', updatePosition)
            } else { // not on screen anymore
                window.removeEventListener('scroll', updatePosition)
            }
        }, {})
        observer.observe(element)
    }
}

export {
    parallax
}
